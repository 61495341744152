import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Footer from "../Components/Footer";
import Aos from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";

const ContactUs = () => {
  const form = useRef();
  const [popup, setPopup] = useState({ type: "", message: "" });

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_hndx319", "template_kfjy0sh", form.current, {
        publicKey: "aptG3iwX7XygkMUe3",
      })
      .then(
        () => {
          setPopup({ type: "success", message: "Message sent successfully!" });
          setTimeout(() => window.location.reload(), 3000);
        },
        (error) => {
          setPopup({
            type: "error",
            message: "Failed to send message. Try again.",
          });
        }
      );
  };

  useEffect(() => {
    Aos.init({ duration: 100 });
  }, []);

  return (
    <div>
      <Helmet>
        <title>Contact | AbaNorbert</title>
        <meta
          name="description"
          content="Get in touch with AbaNorbert Immigration Services Inc. for any inquiries or assistance regarding your immigration needs. We're here to help!"
        />
        <link rel="canonical" href="/contact-us" />
      </Helmet>

      {popup.message && (
        <div
          className={`fixed top-10 left-1/2 transform -translate-x-1/2 px-6 py-3 rounded-lg shadow-lg text-white z-50 ${
            popup.type === "success" ? "bg-green-500" : "bg-red-500"
          }`}
          style={{ zIndex: 9999 }}
        >
          {popup.message}
        </div>
      )}

      <section>
        <div className="relative hero-container">
          <img
            src="https://res.cloudinary.com/dtnz1b8wq/image/upload/f_auto,q_auto/c_limit,w_2000/canad12_x0rjzp"
            loading="lazy"
            className=" hero-image"
          />
          <div className="absolute inset-0 bg-black opacity-70"></div>
          <div className="absolute inset-0 flex flex-col items-center justify-center text-white p-4">
            <h1 className="text-4xl font-bold text-center">
              CONTACT <span className="text-primary">US</span>
            </h1>
            <div className="h-1 w-20 bg-primary mt-2 mb-2"></div>
            <p className="text-xl text-center">
              Contact AbaNorbert Immigration Services
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="b" id="contact">
          <div
            className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-16 text-center"
            data-aos="fade-up"
            data-aos-duration="800"
          >
            <h2 className="text-4xl font-bold">Contact</h2>
            <p className="pt-6 pb-6 text-base max-w-2xl text-center m-auto">
              Want to contact us? Choose an option below and we'll be happy to
              hear from you.
            </p>
          </div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-8 pb-16 grid md:grid-cols-2 lg:grid-cols-2 gap-y-8 md:gap-x-8 md:gap-y-8 lg:gap-x-8 lg:gap-y-16">
            <div>
              <h2 className="text-lg font-bold">Contact Us</h2>
              <p className="max-w-sm mt-4 mb-4">
                Have something to say? We are here to help. Fill up the form or
                send email or call phone.
              </p>
              <div>
                {/* <h2 className="text-lg font-bold">Our Locations</h2>
                <p className="max-w-sm mt-4 mb-4">
                  Visit us at one of our convenient locations or reach out
                  digitally:
                </p> */}

                {/* Original Location */}
                <div className="mb-8">
                  <h3 className="font-semibold mb-2">Montreal Office</h3>
                  <div className="flex items-center mt-2 space-x-2 text-dark-600">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#339ec4"
                      aria-hidden="true"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"
                      />
                    </svg>
                    <span>
                      100 Boulevard Alexis-Nihon, Saint-Laurent, QC, H4M 2P1
                      Office Suite# 595
                    </span>
                  </div>
                </div>

                {/* New Location */}
                <div className="mt-6">
                  <h3 className="font-semibold mb-2">Sherbrooke Office</h3>
                  <div className="flex items-center mt-2 space-x-2 text-dark-600">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#339ec4"
                      aria-hidden="true"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"
                      />
                    </svg>
                    <span>
                      1576 Rue King Ouest, Suite 212, Office#6
                      Sherbrooke, QC J1J 2C3
                    </span>
                  </div>
                </div>

                {/* Shared Contact Info */}
                <div className="mt-8">
                  <div className="flex items-center mt-2 space-x-2 text-dark-600">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#339ec4"
                      aria-hidden="true"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                      />
                    </svg>
                    <a href="mailto:info@abanorbert.ca">info@abanorbert.ca</a>
                  </div>
                  <div className="flex items-center mt-2 space-x-2 text-dark-600">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#339ec4"
                      aria-hidden="true"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                      />
                    </svg>
                    <a href="tel:+1 (514) 568-7173">+1 (514) 568-7173</a>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <form ref={form} onSubmit={sendEmail}>
                <input type="hidden" name="botcheck" />
                <div className="mb-5">
                  <input
                    type="text"
                    placeholder="Full Name"
                    autoComplete="false"
                    className="w-full px-4 py-3 border-2 placeholder:text-gray-800 dark:text-black rounded-md outline-none dark:placeholder:text-gray-900 dark:bg-gray-100 focus:ring-4 border-gray-300 focus:border-gray-600 ring-gray-100 dark:border-gray-600 dark:focus:border-primary dark:ring-0"
                    name="to_name"
                  />
                </div>
                <div className="mb-5">
                  <input
                    id="email_address"
                    type="email"
                    placeholder="Email Address"
                    autoComplete="false"
                    className="w-full px-4 py-3 border-2 placeholder:text-gray-800 dark:text-black rounded-md outline-none dark:placeholder:text-gray-900 dark:bg-gray-100 focus:ring-4 border-gray-300 focus:border-gray-600 ring-gray-100 dark:border-gray-600 dark:focus:border-primary dark:ring-0"
                    name="from_name"
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    placeholder="Your Message"
                    className="w-full px-4 py-3 border-2 placeholder:text-gray-800 dark:text-black rounded-md outline-none dark:placeholder:text-gray-900 dark:bg-gray-100 focus:ring-4 border-gray-300 focus:border-gray-600 ring-gray-100 dark:border-gray-600 dark:focus:border-primary dark:ring-0 h-36"
                    name="message"
                  />
                </div>
                <button
                  type="submit"
                  value="send"
                  className="w-full py-4 font-semibold text-white bg-primary rounded-md"
                  data-aos="zoom-in"
                  data-aos-duration="800"
                >
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2796.6685547506686!2d-73.68249551349649!3d45.49661879649224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc918263e080dab%3A0xb5fa54959d9da640!2s100%20Alexis%20Nihon%2C%20100%20Alexis-Nihon%20Blvd%2C%20Saint-Laurent%2C%20QC%20H4M%202P1%2C%20Canada!5e0!3m2!1sen!2srw!4v1718702998980!5m2!1sen!2srw"
          width="800"
          height="600"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          className="map"
        ></iframe>
      </section>

      <Footer />
    </div>
  );
};

export default ContactUs;
